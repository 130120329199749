import React, { useEffect, useCallback, useContext } from 'react'
import { AppContext } from './Context'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../../CurrentUserContext'
import { useQuery } from '@apollo/client'
import { loader } from 'graphql.macro'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
	Box,
	FormControl,
	FormLabel,
	Grid,
	MenuItem,
	InputLabel,
	Select,
	LinearProgress,
} from '@mui/material'
import { generalInfoInitialValues } from '../EditReportForm/initialValues'
// import TextInput from './ReusableInputs/TextInput'
import RadioGroupInput from './ReusableInputs/RadioGroupInput'

import { isCoordoUser, isFieldUser, sortAlphabetically } from './functions'
import NextButton from './ReusableInputs/NextButton'
import { identifiedPerson } from '../../../defaultValues'

const MISSIONS = loader('./graphql/missions.graphql')
const REPORT_TO = loader('./graphql/allReportTo.graphql')
const NO_INVITIGATION_REASONS = loader(
	'./graphql/allNotTakenInvistigationReasons.graphql'
)
const COMPLAINANT_STATUS_LIST = loader(
	'./graphql/complainantStatusList.graphql'
)

const GeneralInformation = ({ report }) => {
	const { t } = useTranslation()
	const currentUser = useCurrentUser()
	const { formValues, handleChange, yesAndNoRadioOptions } =
		useContext(AppContext)
	const {
		generalInfo_reportDate,
		generalInfo_incidentMission,
		generalInfo_incidentProject,
		generalInfo_caseReportedTo,
		generalInfo_investigationUndertaken,
		generalInfo_investigatedBy,
		generalInfo_noInvestigationReason,
		generalInfo_adviceAskedByGarec,
		generalInfo_complainantStatus,
		generalInfo_affectedPersonIdentified,
	} = formValues
	// Check if all values are not empty and if there are some errors
	const isError = useCallback(
		() =>
			Object.keys({
				generalInfo_reportDate,
				generalInfo_incidentMission,
				generalInfo_incidentProject,
				generalInfo_caseReportedTo,
				generalInfo_investigationUndertaken,
				generalInfo_investigatedBy,
				generalInfo_noInvestigationReason,
				generalInfo_adviceAskedByGarec,
				generalInfo_complainantStatus,
				generalInfo_affectedPersonIdentified,
			}).some(
				(name) =>
					(formValues[name].required && !formValues[name].value) ||
					formValues[name].error
			),
		[
			formValues,
			generalInfo_reportDate,
			generalInfo_incidentMission,
			generalInfo_incidentProject,
			generalInfo_caseReportedTo,
			generalInfo_investigationUndertaken,
			generalInfo_investigatedBy,
			generalInfo_noInvestigationReason,
			generalInfo_adviceAskedByGarec,
			generalInfo_complainantStatus,
			generalInfo_affectedPersonIdentified,
		]
	)
	const coordoUser = currentUser && isCoordoUser({ currentUser })
	const fieldUser = currentUser && isFieldUser({ currentUser })

	useEffect(() => {
		if (report) {
			generalInfoInitialValues.map(({ name, mainKey, subKey }) => {
				//This is na exception so if report has no complainant && no affectedPerson
				//We set it to false
				if (name === 'generalInfo_affectedPersonIdentified') {
					handleChange({
						target: {
							name,
							value: report.complainant
								? true
								: !report.complainant && !report.affectedPerson
								? false
								: !report.complainant && report.affectedPerson
								? true
								: false,
						},
					})
				} else if (subKey === `date`) {
					handleChange({
						target: {
							name,
							value: new Date(report[mainKey]).toISOString().slice(0, 10),
						},
					})
				} else if (subKey) {
					handleChange({
						target: {
							name: name,
							value: report[mainKey]
								? report[mainKey][subKey]
								: report[mainKey],
						},
					})
				} else {
					handleChange({
						target: {
							name: name,
							value: Boolean(report[mainKey]),
						},
					})
				}
			})
		}
	}, [report])

	useEffect(() => {
		if (!report) {
			if ((coordoUser || fieldUser) && currentUser) {
				//If user is coordo or field, we update the value generalInfo_incidentMission
				//to the user's mission by calling hanldeChange to update the Context
				handleChange({
					target: {
						name: 'generalInfo_incidentMission',
						value: currentUser.project.mission.code,
					},
				})
			}
			if (currentUser && fieldUser && !coordoUser) {
				//If user is field user we update value generalInfo_incidentProject
				handleChange({
					target: {
						name: 'generalInfo_incidentProject',
						value: currentUser.project.code,
					},
				})
			}
		}
	}, [coordoUser, fieldUser, currentUser])

	useEffect(() => {
		//If generalInfo_complainantStatus.value is true
		if (generalInfo_complainantStatus.value) {
			const iscomplainantStatusIdentifiedPerson =
				complainantStatusList?.find(({ status }) => status === identifiedPerson)
					?.id === generalInfo_complainantStatus.value
			//If user selects identifiedPerson we reset generalInfo_affectedPersonIdentified value
			if (iscomplainantStatusIdentifiedPerson) {
				handleChange({
					target: {
						name: 'generalInfo_affectedPersonIdentified',
						value: '',
					},
				})
			}
		}
	}, [generalInfo_complainantStatus.value])

	const handleMissionChange = (e) => {
		handleChange(e)
		//If user changes the value of generalInfo_incidentMission
		//We reset the value of generalInfo_incidentProject
		handleChange({
			target: {
				name: 'generalInfo_incidentProject',
				value: '',
			},
		})
	}

	const { data: { missions = null } = {}, loading: missionsLoading } =
		useQuery(MISSIONS)
	const { data: { allReportTo = null } = {}, loading: reportToLoading } =
		useQuery(REPORT_TO)
	const {
		data: { allNotTakenInvistigationReasons = null } = {},
		loading: notTakenInvistigationReasonsLoading,
	} = useQuery(NO_INVITIGATION_REASONS)

	const {
		data: { complainantStatusList = null } = {},
		loading: complainantStatusLoading,
	} = useQuery(COMPLAINANT_STATUS_LIST)

	return (
		<>
			<h2>{t('general_info')}</h2>
			<Grid
				container
				spacing={2}
				direction="column"
				justifyContent="center"
				alignItems="left"
				columns={16}
			>
				<Grid item xs={16}>
					<section>{t('report_date')}</section>
				</Grid>
				<Grid item xs={8} alignItems="left">
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							name="generalInfo_reportDate"
							label={t('date')}
							disableFuture
							//Passing the value to the Context manually as it is not working with the onChange prop
							onChange={(value) =>
								handleChange({
									target: {
										type: 'date',
										name: 'generalInfo_reportDate',
										value: dayjs(value.$d).format('YYYY-MM-DD'),
									},
								})
							}
							format="DD/MM/YYYY"
							required={generalInfo_reportDate.required}
							value={dayjs(generalInfo_reportDate.value)}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item xs={8} />
				<Grid item xs={12}>
					<FormControl
						fullWidth
						required={generalInfo_incidentMission.required}
						disabled={coordoUser || fieldUser}
					>
						<InputLabel id="demo-simple-select-label">
							{t('incident_mission')}
						</InputLabel>
						{missionsLoading ? (
							<LinearProgress color="inherit" />
						) : (
							<Select
								id="generalInfo_incidentMissionId"
								//If user is a field user we disable other options, we set default value to user's mission
								value={
									coordoUser || fieldUser
										? currentUser.project.mission.code
										: generalInfo_incidentMission.value
								}
								name="generalInfo_incidentMission"
								label={t('incident_mission')}
								onChange={handleMissionChange}
							>
								{
									//If user is a cell user, we filter options so we only show missions (countries) within his cell
									missions && currentUser?.cell
										? sortAlphabetically({ arr: missions, sortOn: 'code' })
												.filter(({ projects }) =>
													projects.find(
														({ cell }) => currentUser.cell.code === cell.code
													)
												)
												.map(({ id, code, name }) => (
													<MenuItem
														value={code}
														key={id}
													>{`${code} - ${name}`}</MenuItem>
												))
										: missions &&
										  sortAlphabetically({ arr: missions, sortOn: 'code' }).map(
												({ id, code, name }) => (
													<MenuItem
														value={code}
														key={id}
													>{`${code} - ${name}`}</MenuItem>
												)
										  )
								}
							</Select>
						)}
					</FormControl>
				</Grid>
				{generalInfo_incidentMission.value !== '' && (
					<Grid item xs={12}>
						<FormControl
							fullWidth
							required={generalInfo_incidentProject.required}
							disabled={fieldUser}
						>
							<InputLabel id="demo-simple-select-label">
								{t('incident_project')}
							</InputLabel>
							<Select
								id="generalInfo_incidentProjectId"
								value={
									fieldUser
										? currentUser.project.code
										: generalInfo_incidentProject.value
								}
								name="generalInfo_incidentProject"
								label={t('incident_project')}
								onChange={handleChange}
							>
								{/* If the user is an HRO, we only show the projects within his/her cell */}
								{missions && currentUser?.cell
									? sortAlphabetically({
											arr: missions
												.find(
													({ code }) =>
														code === generalInfo_incidentMission.value
												)
												?.projects.filter(
													({ cell }) => cell.code === currentUser?.cell.code
												),
											sortOn: 'code',
									  })
											//.filter(({ active }) => (Boolean(report) ? true : active))
											.map(({ id, code, name, active }) => (
												<MenuItem
													//Disable non active projects
													//disabled={!active}
													value={code}
													key={id}
												>{`${code} - ${name}`}</MenuItem>
											))
									: missions &&
									  sortAlphabetically({
											arr: missions.find(
												({ code }) => code === generalInfo_incidentMission.value
											)?.projects,
											sortOn: 'code',
									  })
											//.filter(({ active }) => (Boolean(report) ? true : active))
											.map(({ id, code, name, active }) => (
												<MenuItem
													//Disable non active projects
													//disabled={!active}
													value={code}
													key={id}
												>{`${code} - ${name}`}</MenuItem>
											))}
							</Select>
						</FormControl>
					</Grid>
				)}

				<Grid item xs={12}>
					<FormControl fullWidth required={generalInfo_caseReportedTo.required}>
						<InputLabel>{t('report_to')}</InputLabel>
						{reportToLoading ? (
							<LinearProgress color="inherit" />
						) : (
							<Select
								id="generalInfo_caseReportedToId"
								value={generalInfo_caseReportedTo.value}
								name="generalInfo_caseReportedTo"
								label={t('report_to')}
								onChange={handleChange}
							>
								{allReportTo &&
									allReportTo.map(({ to, id }) => (
										<MenuItem value={id} key={id}>
											{t(to)}
										</MenuItem>
									))}
							</Select>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControl>
						<FormLabel>{t('investigation_undertaken')} ?</FormLabel>
						<RadioGroupInput
							id="generalInfo_investigationUndertaken"
							name="generalInfo_investigationUndertaken"
							value={generalInfo_investigationUndertaken.value}
							array={yesAndNoRadioOptions}
						/>
					</FormControl>
				</Grid>
				{!generalInfo_investigationUndertaken.value && (
					<Grid item xs={12}>
						<FormControl
							fullWidth
							required={!generalInfo_investigationUndertaken.value}
						>
							<InputLabel id="demo-simple-select-label">
								{t('why_no_invistigation')}
							</InputLabel>
							{notTakenInvistigationReasonsLoading ? (
								<LinearProgress color="inherit" />
							) : (
								<Select
									id="generalInfo_noInvestigationReason"
									value={generalInfo_noInvestigationReason.value}
									name="generalInfo_noInvestigationReason"
									label={t('why_no_invistigation')}
									onChange={handleChange}
								>
									{allNotTakenInvistigationReasons &&
										allNotTakenInvistigationReasons.map(({ reason, id }) => (
											<MenuItem value={id} key={id}>
												{t(reason)}
											</MenuItem>
										))}
								</Select>
							)}
						</FormControl>
					</Grid>
				)}
				{generalInfo_investigationUndertaken.value && (
					<Grid item xs={12}>
						<FormControl
							fullWidth
							required={generalInfo_investigationUndertaken.value}
						>
							<InputLabel id="demo-simple-select-label">
								{t('investigated_by')}
							</InputLabel>
							{reportToLoading ? (
								<LinearProgress color="inherit" />
							) : (
								<Select
									id="generalInfo_investigatedById"
									value={generalInfo_investigatedBy.value}
									name="generalInfo_investigatedBy"
									label={t('investigated_by')}
									onChange={handleChange}
								>
									{
										//Remove 'Others' from the list
										allReportTo &&
											allReportTo
												.filter(({ to }) => to !== 'Others')
												.map(({ to, id }) => (
													<MenuItem value={id} key={id}>
														{t(to)}
													</MenuItem>
												))
									}
								</Select>
							)}
						</FormControl>
					</Grid>
				)}
				{
					//If it has been invistigated by 'Garec' we disable this input
					allReportTo && generalInfo_investigatedBy.value !== ''
						? !(
								allReportTo.find(({ to }) => to === 'GAREC')?.id ===
								generalInfo_investigatedBy.value
						  ) &&
						  generalInfo_investigationUndertaken.value && (
								<Grid item xs={12}>
									<FormControl>
										<FormLabel>{t('garec_advice')}</FormLabel>
										<RadioGroupInput
											id="generalInfo_adviceAskedByGarec"
											name="generalInfo_adviceAskedByGarec"
											value={generalInfo_adviceAskedByGarec.value}
											array={yesAndNoRadioOptions}
										/>
									</FormControl>
								</Grid>
						  )
						: null
				}

				<Grid item xs={12}>
					<FormControl
						fullWidth
						required={generalInfo_complainantStatus.required}
					>
						{(generalInfo_investigatedBy.value ||
							generalInfo_noInvestigationReason.value) && (
							<>
								<InputLabel>{t('complainant_is')}</InputLabel>
								{complainantStatusLoading ? (
									<LinearProgress color="inherit" />
								) : (
									<Select
										id="generalInfo_complainantStatusId"
										value={generalInfo_complainantStatus.value}
										name="generalInfo_complainantStatus"
										label={t('complainant_is')}
										onChange={handleChange}
									>
										{complainantStatusList &&
											complainantStatusList.map(({ status, id }) => (
												<MenuItem value={id} key={id}>
													{t(status)}
												</MenuItem>
											))}
									</Select>
								)}
							</>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					{generalInfo_complainantStatus.value &&
						//When user selects any option other than 'An identified person' We show this question (affectedPerson_identified)
						complainantStatusList?.find(
							({ status }) => status === identifiedPerson
						)?.id !== generalInfo_complainantStatus.value && (
							<FormControl>
								<FormLabel>{t('affectedPerson_identified')}</FormLabel>
								<RadioGroupInput
									id="affectedPerson_identified"
									name="generalInfo_affectedPersonIdentified"
									value={generalInfo_affectedPersonIdentified.value}
									array={yesAndNoRadioOptions}
								/>
							</FormControl>
						)}
				</Grid>
			</Grid>

			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<NextButton
					error={
						isError() ||
						(generalInfo_investigatedBy.value === '' &&
							generalInfo_noInvestigationReason.value === '') ||
						(complainantStatusList?.find(
							({ status }) => status === identifiedPerson
						)?.id !== generalInfo_complainantStatus.value &&
							typeof generalInfo_affectedPersonIdentified.value !== 'boolean')
					}
					/**If user selects 'An identified person' from generalInfo_complainantStatusId question
					 *We move to the next page directly.
					 *If the user selects 'Yes' true from generalInfo_affectedPersonIdentified we skip to the second step
					 *If the user selects 'No' false from generalInfo_affectedPersonIdentified we skip to the third step
					 */
					number={
						generalInfo_complainantStatus.value &&
						complainantStatusList?.find(
							({ status }) => status === identifiedPerson
						)?.id === generalInfo_complainantStatus.value
							? 1
							: generalInfo_affectedPersonIdentified.value
							? 2
							: 3
					}
				/>
			</Box>
		</>
	)
}

export default GeneralInformation
