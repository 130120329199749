import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCurrentUser } from '../CurrentUserContext'

export const RedirectUserContext = React.createContext()

export const RedirectUserProvider = ({ children }) => {
	const navigate = useNavigate() // Initialize navigate
	const currentUser = useCurrentUser()

	// Redirect to /error if currentUser is falsy
	useEffect(() => {
		if (!currentUser) {
			navigate('/')
		}
		//If user has not been configured as one of the main 5 profiles, redirect to the error access page
		else if (
			currentUser &&
			!currentUser?.garec &&
			!currentUser?.behaviourFocalPoint &&
			!currentUser?.cell &&
			!currentUser?.project
		) {
			navigate('/access-error')
		}
	}, [currentUser])

	return (
		<RedirectUserContext.Provider value="">
			{children}
		</RedirectUserContext.Provider>
	)
}
