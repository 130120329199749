import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useCurrentUser } from '../../CurrentUserContext'
import { useTranslation } from 'react-i18next'
import { loader } from 'graphql.macro'
import { FormControl, MenuItem, InputLabel, Select } from '@mui/material'
import ReportTable from '../../UI/ReportTable'
import Header from '../../UI/Header'
import Progress from '../../UI/Progress'
import { sortAlphabetically, isCellUser } from '../ReportForm/functions'

const REPORTS_BY_CELL = loader('./graphql/reportsByCell.graphql')
const CELLS = loader('./graphql/cells.graphql')

const CellReports = () => {
	const currentUser = useCurrentUser()
	const { t } = useTranslation()
	const [cellCode, setCellCode] = useState(() => {
		return window.localStorage.getItem('cellCode')
	})
	const { data: { cells = null } = {}, loading: cellLoading } = useQuery(CELLS)

	const [
		fetchReportsByCellCode,
		{ data: { reportsByCell = null } = {}, loading },
	] = useLazyQuery(REPORTS_BY_CELL)

	useEffect(() => {
		//If user is
		if (currentUser) {
			const cellUser = isCellUser({ currentUser })
			if (cellUser) {
				fetchReportsByCellCode({
					variables: {
						cellCode: currentUser.cell.code,
					},
				})
			}
		}
	}, [currentUser])

	useEffect(() => {
		if (cellCode) {
			window.localStorage.setItem('cellCode', cellCode)
			fetchReportsByCellCode({
				variables: {
					cellCode,
				},
			})
		}
	}, [cellCode])

	return (
		<>
			<Header />
			{cellLoading ? (
				<Progress />
			) : (
				<>
					<FormControl
						style={{ minWidth: 200, marginBottom: '20px' }}
						disabled={Boolean(currentUser.cell)}
					>
						<InputLabel id="demo-simple-select-label">
							{t('select_cell')}
						</InputLabel>
						<Select
							value={currentUser.cell?.code || cellCode}
							name="reportCell"
							label={t('select_cell')}
							onChange={({ target }) => setCellCode(target.value)}
						>
							{cells &&
								sortAlphabetically({ arr: cells, sortOn: 'code' }).map(
									({ id, code }) => (
										<MenuItem value={code} key={id}>
											{code}
										</MenuItem>
									)
								)}
						</Select>
					</FormControl>
					{loading ? (
						<Progress />
					) : (
						reportsByCell && <ReportTable reports={reportsByCell} />
					)}
				</>
			)}
		</>
	)
}

export default CellReports
